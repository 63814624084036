.coupon-code {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
    text-align: center;
    min-width: fit-content;
}

.coupon-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.copy-btn {
    margin-left: 0.5rem;
}

.share-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    margin: 20px auto;
}

.share-buttons img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}