.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-padding {
  padding-left: 50px;
}

.nav-color {
  background-color: #1f2532 !important;
  height: 200px;
}

.slogan {
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  white-space: normal; /* allow text wrapping */
  width: 100%; 
}

.navbar-brand {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.highlight {
  font-weight: bold;
}

.first-letter {
  font-size: 2rem;
}

/* Adjust font size on smaller screens */
@media (max-width: 768px) {
  .slogan {
    font-size: 0.8rem;
  }

  .first-letter {
    font-size: 1.6rem;
  }
}
