.home {
    padding: 1em;
    background-color: #f9f9f9;
  }
  
  .company-card {
    transition: transform .2s;
  }
  
  .company-card:hover {
    transform: scale(1.03);
  }
  
  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
  }
  
  .text-center::placeholder {
    text-align: center;
  }